import React from "react"
import ShareSuit from "../Shared/SocialMedia/ShareSuit"
import Blogs from "./Blogs";
import authorIcon from "../icons/Writer.svg";
import calendarIcon from "../icons/Calendar.svg";

const Blog = ({ blog, relatedBlogs }) => {
    let imageURL = blog.Thumbnail.localFile.publicURL
    let published = new Date(blog.PublishedAt);
                        published =
                            published.toLocaleString('en-GB', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric'
                            }).toUpperCase();
    let categories = blog.categories.map(c => {
        return (<a className="blog-category" href={"/blog/category/" + c.URL}>{c.Name}</a>)
    }).reduce((prev, curr) => [prev, ' | ', curr]);
    
    return (
        <div>
            <div className="one-blog-container">
                <h1 className="blog-header">{blog.Header}</h1>
                <div className="blog-info">
                    <span className="blog-author">
                        <div className="blog-author-icon" style={{backgroundImage: `url(${authorIcon})`}}></div>
                        {blog.Author}
                    </span>
                    <span className="blog-date">
                        <div className="blog-date-icon" style={{backgroundImage: `url(${calendarIcon})`}}></div>
                        {published}
                    </span>
                </div>
                <p className="blog-categories">Categories:{categories}</p>
                <div className="image-container">
                    <img src={imageURL} alt="blog" />
                </div>
                <div className="content-container df single-blog-icons-size">
                    <p className="blog-description px-8" dangerouslySetInnerHTML={{ __html: blog.Body }}></p>
                    <div className="single-blog-share-icons">
                        <ShareSuit />
                    </div>
                </div>
                <div className="single-blog-mobile-share-icons">
                    <ShareSuit direction="row" />
                </div>
            </div>
            {
                relatedBlogs?.length > 0 && (
                    <div className="one-blog-more-articles lg:container">
                        <h2 className="article-header">Related Articles</h2>
                        <Blogs blogs={relatedBlogs} />
                    </div>
                )
            }
        </div>
    )
}

export default Blog
