import React from "react";
import authorIcon from "../icons/Writer.svg";
import calendarIcon from "../icons/Calendar.svg";

const Blogs = ({ blogs }) => {
    return (
        <>
            <div className="blog-card-container">
            {
                blogs.map((blog, index) => {
                    let imageURL = blog.Thumbnail.localFile.publicURL;
                    let published = new Date(blog.PublishedAt);
                        published =
                            published.toLocaleString('en-GB', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric'
                            }).toUpperCase();
                    let categories = blog.categories.map(c => {
                        return (<a className="blog-category" href={"/blog/category/" + c.URL}>{c.Name}</a>)
                    }).reduce((prev, curr) => [prev, ' | ', curr]);
                    let description = blog.Description.length > 64 ?
                        blog.Description.substr(0, blog.Description.lastIndexOf(" ", 64)) + "..." :
                        blog.Description;

                    return (
                        <div className="blog-card">
                            <div className="image-container">
                                <a href={"/blog/" + blog.URL}><img src={imageURL} alt="blog"/></a>
                            </div>
                            <div className="content-container">
                                <p className="blog-categories">{categories}</p>
                                <h3 className="blog-header"><a href={"/blog/" + blog.URL}>{blog.Header}</a></h3>
                                <p className="blog-desc">{description} <a href={"/blog/" + blog.URL}>Read More</a></p>
                            </div>
                            <div className="blog-footer">
                                <span className="blog-author">
                                    <div className="blog-author-icon" style={{backgroundImage: `url(${authorIcon})`}}></div>
                                    {blog.Author}
                                </span>
                                <span className="blog-date">
                                    <div className="blog-date-icon" style={{backgroundImage: `url(${calendarIcon})`}}></div>
                                    {published}
                                </span>
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </>
    )
}

export default Blogs
