import React, { useEffect } from "react"
import { Helmet } from "react-helmet";
import facebookIcon from "../../icons/facebook.svg";
import twitterIcon from "../../icons/twitter.svg";
import linkedInIcon from "../../icons/linkedin.svg";

const ShareSuit = ({direction}) => {
    let flexDirection = ""

    switch (direction) {
        case "row":
            flexDirection = "fd-r";
            break;
        // case "column":
        default:
            flexDirection = "fd-c";
            break;
    }

    let wasExecuted = false;

    const UpdateCustomButtons = (event) => {
        if (!wasExecuted) {
            wasExecuted = true;
            eval(`
                var old_elements = document.getElementsByClassName("st-custom-button");
                for (let i = 0; i < old_elements.length; i++) {
                    var old_element = old_elements[i];
                    var new_element = old_element.cloneNode(true);
                    old_element.parentNode.replaceChild(new_element, old_element);
                }

                st.loader['custom-share-buttons']()
            `);

            event.target.click();
        }
    }

    return (
        <>
            <Helmet>
                <script
                    type="text/javascript"
                    src="//platform-api.sharethis.com/js/sharethis.js#property=62ff4fa0f4696f0019bda232&product=custom-share-buttons">
                </script>
            </Helmet>
            <div className={`df ${flexDirection} share-suit`} onMouseDown={UpdateCustomButtons}>
                <div className="st-custom-button" data-network="facebook">
                    <img src={facebookIcon} alt="Facebook" />
                </div>
                <div className="st-custom-button" data-network="twitter">
                    <img src={twitterIcon} alt="Twitter" />
                </div>
                <div className="st-custom-button" data-network="linkedin">
                    <img src={linkedInIcon} alt="Linked In" />
                </div>
            </div>   
        </>
    )
}

export default ShareSuit;