import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import LayoutHeadFoot from "../components/Shared/Layouts/LayoutHeadFoot";
import SEO from "../components/seo";
import Blog from "../components/BlogPage/Blog";

const useCasesTemplate = ({ data }) => {
    let scrollCount = 0;

    useEffect(() => {
        if (scrollCount === 0) {
            window.scrollTo(0, 0)
            scrollCount++;
        }
    })
        
    let related = data?.filtered?.nodes ?? [];
    
    data.unfiltered.nodes.forEach(node => {
        if (related.filter(rel => rel.URL === node.URL).length === 0)
        related.push(node);
    });

    related = related.slice(0, 3);
    
    return (
        <>
            <SEO seo={data.strapiBlogs.metaData} />
            <LayoutHeadFoot>
            <div className="gradientBlue">
                <div className="mb-24 pt-16">
                    <Blog
                        blog={data?.strapiBlogs}
                        relatedBlogs={related} />
                </div>
            </div>
            </LayoutHeadFoot>
        </>
    )
}

export const query = graphql`
query strapiBlogsQuery($id: String!, $categories: [Int]) {
    strapiBlogs(id: {eq: $id}) {
        metaData {
            metaTitle
            metaDescription
            shareImage {
                localFile {
                    publicURL
                }
            }
        }
        Header
        Author
        Body
        Thumbnail {
            localFile {
                publicURL
            }
        }
        categories {
            id
            Name
            URL
        }
        PublishedAt
        Type
    }
    filtered: allStrapiBlogs(limit: 3, filter: {categories: {elemMatch: {id: {in: $categories}}}, id: {ne: $id}}) {
        nodes {
            URL
            Description
            Author
            Header
            Thumbnail {
                localFile {
                    publicURL
                }
            }
            categories {
                URL
                Name
            }
            PublishedAt
            Type
        }
      }
      unfiltered: allStrapiBlogs(limit: 3, filter: {id: {ne: $id}}) {
          nodes {
              URL
              Description
              Author
              Header
            Thumbnail {
                localFile {
                    publicURL
                }
            }
            categories {
                Name
                URL
            }
            PublishedAt
            Type
        }
    }
}`

export default useCasesTemplate;
